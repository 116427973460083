/**
 * Convert a CSV array to an array of objects
 *
 * @param {Array} result
 * @returns {Array}
 */
export default function csvToJson(result) {
  // Get the keys
  const keys = result[0]

  // The array of objects to send
  const items = []

  // Go through each row
  for (let i = 1; i < result.length; i++) {
    // The row
    const row = result[i]

    // The object to push
    const obj = {}

    // Go through each column
    for (let j = 0; j < row.length; j++) {
      // The key
      const key = keys[j]

      // The value
      const value = row[j]

      // Set the value
      obj[key] = value
    }

    // Push the object
    items.push(obj)
  }

  // Return the array of objects
  return items
}
